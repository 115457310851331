import {useStoreState} from "pullstate";
import {UserStore} from "../../../Domains/User/UserStore";
import {useIonModal} from "@ionic/react";
import VendorModal from "../../../Domains/Vendor/VendorModal";
import {ProductModal} from "../../../Domains/Product/ProductModal";
import {setVendor} from "../../../Domains/Vendor/VendorStore";
import Product from "../../../Domains/Product/ProductClass";
import {loadProduct} from "../../../Domains/Product/ProductStore";
import AvatarTitleSub from "../../../Components/AvatarTitleSub/AvatarTitleSub";
import BeyondLocalBadge from "../../../Components/Badges/BeyondLocalBadge";
import GeneralStoreBadge from "../../../Components/Badges/GeneralStoreBadge";
import dayjs from "dayjs";
import {CoverImage} from "../../../Components/CoverImage/CoverImage";
import ProductBuyBar from "../../../Domains/Product/ProductItem/ProductBuyBar";
import styles from './productTile.module.css';

const ProductTile = (props: any) => {
  const userState = useStoreState(UserStore);
  const [presentVendorModal, dismissVendorModal] = useIonModal(VendorModal, {
    onDismiss: () => {
      dismissVendorModal();
    },
    router: props.router,
  });

  const [presentProductModal, dismissProductModal] = useIonModal(ProductModal, {
    onDismiss: () => {
      dismissProductModal();
    },
    router: props.router,
  });

  const localOpenVendor = (vendor: any) => {
    setVendor(vendor);
    presentVendorModal();
  };

  const localOpenProduct = (product: Product) => {
    if (product && product.id) {
      loadProduct(product).then();
      presentProductModal();
    }
  };

  const imageClicked = () => {
    if (props.product) {
      localOpenProduct(props.product);
    } else if (props.vendor) {
      localOpenVendor(props.vendor);
    }
  };

  const isTextOnlyVendorPost = () => {
    return (
      props.type === 'vendor-post' &&
      props.text &&
      (!props.textImage || props.textImage === '')
    );
  };
  return (
    <div
      className={`flex flex-col bg-white rounded-lg shadow-lg overflow-hidden relative PostCard ${(props.type === 'vendor-post' ? 'border-2 border-green-600' : '')}`}>
      {props.type === 'staff-trending' && props.product?.ribbonText && (
        <div className={`${styles.ProductTileRibbonGreen}`}>On Sale!</div>
      )}
      {(props.type === 'new' || props.type === 'new-item') && (
        <div className={`${styles.ProductTileRibbonOrange}`}>New Item</div>
      )}
      {props.type === 'new-vendor' && (
        <div className={`${styles.ProductTileRibbonOrange}`}>New Vendor</div>
      )}
      {props.type === 'on-sale' && (
        <div className={`${styles.ProductTileRibbonGreen}`}>On Sale!</div>
      )}
      {props.type === 'staff-pick' && (
        <div className={`${styles.ProductTileRibbonBlue}`}>Staff Pick</div>
      )}
      {props.type === 'staff-flash-sale' && (
        <div className={`${styles.ProductTileRibbonPurple}`}>Flash Sale</div>
      )}

      {(props.title || props.avatar || props.avatarLabel || props.subtitle) && (
        <header className='flex items-center justify-between px-2 pt-2'>
          <AvatarTitleSub
            onClick={() => {
              localOpenVendor(props.vendor || props.product?.vendor);
            }}
            title={props.title}
            image={props.avatar}
            avatarLabel={props.avatarLabel}
            subtitle={props.subtitle}
          ></AvatarTitleSub>
        </header>
      )}

      {(props.text || props.textImage || props.product) &&
        !isTextOnlyVendorPost() && (
          <main className='px-2 py-2'>

            {props.textImage && (
              <div>
                {props.product && props.product.badge === 2 && !props.product.isPreOrder &&
                  <BeyondLocalBadge classes='absolute z-20 h-auto right-10'/>
                }

                {props.product && props.product.badge === 3 && !props.product.isPreOrder &&
                  <GeneralStoreBadge classes='absolute z-20 h-auto right-10'/>
                }

                {props.product && props.product.badge === 4 && !props.product.isPreOrder &&
                  <div className='absolute z-20 right-10 shadow-xl bg-black h-auto p-2'>
                    <p className='text-gray-100 font-semibold text-sm leading-tight tracking-tight'>
                      Seeds <br/> <span className='text-gray-400'>and</span> <br/> Starts
                    </p>
                  </div>
                }

                {props.product && props.product.isPreOrder && props.product.variants[0]?.preOrderDate &&
                  <div className='shadow-lg absolute z-20 h-auto right-10'>
                    <img
                      src='/images/badges/pre-order.png'
                      alt={`Preorder ${props.product?.title} - Arrives: ${dayjs(props.product?.variants[0]?.preOrderDate.split('.000Z')[0]).format('MM/DD/YYYY')}`}
                      width='67'
                      height='51'
                    />
                  </div>
                }

                <button
                  onClick={imageClicked}
                  aria-label='Post Photo'
                  className='PostCard__image transition flex items-center justify-center transform duration-100 rounded-lg bg-gray-200 hover:scale-95 mt-0 w-full'
                >
                  {/* Placeholder Image Text */}
                  <span className='text-gray-400 absolute'>
                    Photo coming soon.
                  </span>

                  <CoverImage
                    className={`flex w-full justify-center items-center border border-gray-200 h-44 rounded-lg md:h-48`}
                    image={props.textImage}
                    title={props.title || 'post photo'}
                  />
                </button>

              </div>
            )}

            {/* Show Text if Included */}
            {props.text && (
              <h2 className={'leading-snug w-full truncate font-semibold text-base 2xl:text-lg mt-2'}>
                {props.text}
              </h2>
            )}
          </main>
        )}

      {props.text && isTextOnlyVendorPost() && (
        <main className='px-2 py-2 md:px-4 md:py-4'>
          <div
            style={{backgroundColor: '#f1f1f1'}}
            className={'tracking-tight md:leading-relaxed rounded-lg w-full flex-grow flex-shrink text-lg md:text-xl font-bold text-green-600 px-6 py-6 md:px-10 md:py-10'}
          >
            {props.text}
          </div>
        </main>
      )}

      {/* Buffer to keep footer at the bottom in case some items in grid mode are not the same height */}
      <div className='buffer max-h-full min-h-0 h-full'></div>

      <footer className='px-2 pb-2 flex items-end flex-grow-0 flex-shrink-0'>
        {/*
          If we have a product show the lite Product Buy Bar (hide details)
        */}
        {!!props.product && (
          <ProductBuyBar
            hideDetails={true}
            className={`w-full ${props.type === 'vendor-post' && userState.isGuest === false ? 'mr-4' : ''}`}
            product={props.product}
            context='feed'
          />
        )}
      </footer>
    </div>
  )
}

export default ProductTile;
